const SmoothScroll = require('smooth-scroll');

// Smooth scroll behaviro for the menu links
new SmoothScroll('a[href*="#"]', {
  header: '[data-scroll-header]',
  easing: 'easeInOutCubic',
  speed: 2000,
  topOnEmptyHash: true,
  updateURL: true,
});


// Listener for applying a class to the navbar on scroll
window.addEventListener('scroll', event => {
  const menu = document.getElementById('menu'); 
  const scrollPosY = window.pageYOffset | document.body.scrollTop;

  if(scrollPosY > 50) {
    menu.classList.add(`scrolling`);
  } else if(scrollPosY <= 100) {
    menu.classList.remove(`scrolling`);
  }
});


// Custom JS

AOS.init();

// submit form
var submit = document.querySelectorAll(".submit-form form");

submit.forEach((e) => {
  e.addEventListener('submit', (evt) => {
    evt.preventDefault();
    var email = e.querySelector('input[type=email]');
    var msgEle = e.parentNode.parentNode.querySelector('.msg');
    console.log(e, email, msgEle);
    var urls = {
      dev: 'https://api.airtable.com/v0/appcanVrH06Sd1VMb/Table%201?api_key=keycCyXRKrWuEZbwY',
    }

    if (validateEmail(email, msgEle)) {
      var data = "{\"fields\": { \"Email\": \"" + email.value + "\", \"Date Registered\": \"" + (new Date()).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ") + "\" }}";
      fetch(urls['dev'], {
        body: data,
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST"
      }).then((response) => {
        if (response.ok == true) {
          msgEle.innerHTML = "<p>Thank you for your interest. We will be in touch with you soon.</p>";
          msgEle.classList.add("success", "active");
          var s = setTimeout(() => {
            if (msgEle.classList.contains("success")) {
              msgEle.classList.remove("success", "active");
            }
            clearTimeout(s);
          }, 8000);
        }
      })
    }
  });
})

function validateEmail(inputText, msgEle) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //console.log(msgEle);
  if (inputText.value.match(mailformat)) {
    //document.form1.text1.focus();
    return true;
  }
  else if (inputText.value=='') {
    msgEle.innerHTML = "<p>Email empty.</p>";
    msgEle.classList.add("error", "active");
    var e = setTimeout(() => {
      if (msgEle.classList.contains("error")) {
        msgEle.classList.remove("error", "active");
      }
      clearTimeout(e);
    }, 3000);
    return false;
  }
  else {
    msgEle.innerHTML = "<p>Email address invalid.</p>";
    msgEle.classList.add("error", "active");
    var e = setTimeout(() => {
      if (msgEle.classList.contains("error")) {
        msgEle.classList.remove("error", "active");
      }
      clearTimeout(e);
    }, 3000);
    return false;
  }
}
